@import url("https://fonts.googleapis.com/css?family=Droid+Sans+Mono");
@import "../../styles/defaults";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

header {
  display: flex;
  position: relative;
}
header h1 {
  font-family: "Droid Sans Mono", monospace;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: $home-countdown-text;
}

.flipClock {
  display: flex;
  justify-content: space-between;
  width: 500px;
}

.flipUnitContainer {
  display: block;
  position: relative;
  width: 140px;
  height: 120px;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  -webkit-perspective: 300px;
  perspective: 300px;
  background-color: $home-countdown-background;
  border-radius: 5px;
  margin: 10px;
}

.units{
  color: $home-countdown-unit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upperCard,
.lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border: 0;
}
.upperCard span,
.lowerCard span {
  font-size: 5em;
  font-family: "Droid Sans Mono", monospace;
  font-weight: 400;
  color: $home-countdown-text;
}

.upperCard {
  align-items: flex-end;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.upperCard span {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.lowerCard {
  align-items: flex-start;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.lowerCard span {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flipCard span {
  font-family: "Droid Sans Mono", monospace;
  font-size: 5em;
  font-weight: 400;
  color: $home-countdown-text;
}
.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  background-color: $home-countdown-background;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.flipCard.unfold span {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  background-color: $home-countdown-background;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.flipCard.fold span {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1
    normal forwards;
  animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal
    forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1
    normal forwards;
  animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal
    forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

@-webkit-keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}

@keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}
@-webkit-keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
@keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}

@media (max-width: 640px) {
  .flipClock {
      width: 350px;
  }

  .flipUnitContainer {
    width: 70px;
    height: 60px;
  }
  .upperCard span,
  .lowerCard span {
    font-size: 3em;
    font-family: "Droid Sans Mono", monospace;
    font-weight: 400;
    color: $home-countdown-text;
  }

  .flipCard span {
    font-family: "Droid Sans Mono", monospace;
    font-size: 3em;
    font-weight: 400;
    color: $home-countdown-text;
  }

}
