@import '../styles/defaults';

.footer {
    padding: 20px 20px 0 20px;
    background: $footer-background !important;
    text-align: center;
    color: $footer-color !important;
    height: 88px;
    left: 0;
    bottom: 0;
    right: 0;
    a {
        color: $footer-link-color;
        font-weight: bold;
        &:hover {
            color: $footer-link-color-over;
        }
    }

    @media (max-width: 640px) {
        height: 100% !important;
    }
}
